import { CatalogProvider } from '@/context/Catalog';
import { FiltersProvider } from '@/context/Filters';
import { LicenceType } from '@/utils/multiDomains';
import GameCatalogWithFilters, {
  SeoContentCatalog
} from './CatalogWithFilters';
import { GameCatalogItem, GameDataStore } from '@/services/getCatalog';

export type SeoParams = { [key in string]: string };
export type CatalogWrapperType = {
  dataStore: GameDataStore;
  games: GameCatalogItem[];
  license: LicenceType;
  seoContent?: SeoContentCatalog;
  seoParams?: SeoParams;
  bannersCategory?: string;
};
const CatalogWrapper = ({
  dataStore,
  games,
  license,
  seoContent,
  seoParams,
  bannersCategory = 'all'
}: CatalogWrapperType) => {
  return (
    <CatalogProvider games={games} dataStore={dataStore} licence={license}>
      <FiltersProvider>
        <GameCatalogWithFilters
          seoContent={seoContent}
          seoParams={seoParams}
          bannersCategory={bannersCategory}
        />
      </FiltersProvider>
    </CatalogProvider>
  );
};

export default CatalogWrapper;
